exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buscar-js": () => import("./../../../src/pages/buscar.js" /* webpackChunkName: "component---src-pages-buscar-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-templates-hoteles-global-economicos-template-js": () => import("./../../../src/templates/hoteles/global/economicos-template.js" /* webpackChunkName: "component---src-templates-hoteles-global-economicos-template-js" */),
  "component---src-templates-hoteles-global-grandes-template-js": () => import("./../../../src/templates/hoteles/global/grandes-template.js" /* webpackChunkName: "component---src-templates-hoteles-global-grandes-template-js" */),
  "component---src-templates-hoteles-index-template-js": () => import("./../../../src/templates/hoteles/index-template.js" /* webpackChunkName: "component---src-templates-hoteles-index-template-js" */),
  "component---src-templates-hoteles-locations-home-template-js": () => import("./../../../src/templates/hoteles/locations/home-template.js" /* webpackChunkName: "component---src-templates-hoteles-locations-home-template-js" */),
  "component---src-templates-index-template-js": () => import("./../../../src/templates/index-template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-informacion-article-template-js": () => import("./../../../src/templates/informacion/article-template.js" /* webpackChunkName: "component---src-templates-informacion-article-template-js" */),
  "component---src-templates-informacion-index-template-js": () => import("./../../../src/templates/informacion/index-template.js" /* webpackChunkName: "component---src-templates-informacion-index-template-js" */),
  "component---src-templates-informacion-section-template-js": () => import("./../../../src/templates/informacion/section-template.js" /* webpackChunkName: "component---src-templates-informacion-section-template-js" */),
  "component---src-templates-links-index-template-js": () => import("./../../../src/templates/links/index-template.js" /* webpackChunkName: "component---src-templates-links-index-template-js" */),
  "component---src-templates-links-links-template-js": () => import("./../../../src/templates/links/links-template.js" /* webpackChunkName: "component---src-templates-links-links-template-js" */),
  "component---src-templates-noticias-category-template-js": () => import("./../../../src/templates/noticias/category-template.js" /* webpackChunkName: "component---src-templates-noticias-category-template-js" */),
  "component---src-templates-noticias-noticia-template-js": () => import("./../../../src/templates/noticias/noticia-template.js" /* webpackChunkName: "component---src-templates-noticias-noticia-template-js" */),
  "component---src-templates-noticias-noticias-template-js": () => import("./../../../src/templates/noticias/noticias-template.js" /* webpackChunkName: "component---src-templates-noticias-noticias-template-js" */),
  "component---src-templates-noticias-topic-index-template-js": () => import("./../../../src/templates/noticias/topic-index-template.js" /* webpackChunkName: "component---src-templates-noticias-topic-index-template-js" */),
  "component---src-templates-noticias-topic-template-js": () => import("./../../../src/templates/noticias/topic-template.js" /* webpackChunkName: "component---src-templates-noticias-topic-template-js" */)
}

